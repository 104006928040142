import { createApp } from 'vue';
import Oruga from '@oruga-ui/oruga-next';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import { MotionPlugin } from '@vueuse/motion';
import FloatingVue from 'floating-vue';
import Toast, { PluginOptions, POSITION } from 'vue-toastification';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import VueDatePicker from '@vuepic/vue-datepicker';

import 'unfonts.css';
import 'animate.css';
import './style.css';
import 'floating-vue/dist/style.css';
import 'vue-toastification/dist/index.css';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import '@oruga-ui/oruga-next/dist/oruga-full-vars.min.css';

import App from './App.vue';

import { getAuth } from '@firebase/auth';
import { initApp } from './firebase';
import firebaseConfig from './firebaseConfig';
import { createI18n } from './i18n';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faCircleNotch } from '@fortawesome/pro-light-svg-icons/faCircleNotch';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash';
import { faHouse } from '@fortawesome/pro-light-svg-icons/faHouse';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons/faMagnifyingGlass';
import { faSpinner } from '@fortawesome/pro-light-svg-icons/faSpinner';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faPenToSquare } from '@fortawesome/pro-light-svg-icons/faPenToSquare';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons/faTrashCan';
import { faXmark } from '@fortawesome/pro-light-svg-icons/faXmark';
import { faDownload } from '@fortawesome/pro-light-svg-icons/faDownload';
import { faAngleUp } from '@fortawesome/pro-light-svg-icons/faAngleUp';
import { faBarsFilter } from '@fortawesome/pro-light-svg-icons/faBarsFilter';
import { faFilter } from '@fortawesome/pro-light-svg-icons/faFilter';
import { faFilterSlash } from '@fortawesome/pro-light-svg-icons/faFilterSlash';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { VueQueryPlugin } from '@tanstack/vue-query';
import * as validators from '@vuelidate/validators';

import HField from '@/components/base/HField.vue';
import PasswordMeter from 'vue-simple-password-meter';
import randomBgImage from './directives/random-bg-image';
import bulkEdit from '@/directives/bulk-edit';
import pinia from '@/store';
import router from '@/router';
import acl from '@/acl';
import authGuard from '@/router/guards';
import { faCircleCheck } from '@fortawesome/pro-light-svg-icons/faCircleCheck';

declare const __APP_VERSION__: string;

const fbApp = initApp(firebaseConfig);
let app: ReturnType<typeof createApp>;

export const i18n = createI18n();
const { createI18nMessage } = validators;

export const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) });

library.add(faEye);
library.add(faEyeSlash);
library.add(faGoogle);
library.add(faExclamationCircle);
library.add(faCircleNotch);
library.add(faTimes);
library.add(faSpinner);
library.add(faUser);
library.add(faAngleLeft);
library.add(faAngleRight);
library.add(faMagnifyingGlass);
library.add(faCheck);
library.add(faHouse);
library.add(faChevronDown);
library.add(faPenToSquare);
library.add(faTrashCan);
library.add(faXmark);
library.add(faDownload);
library.add(faAngleUp);
library.add(faBarsFilter);
library.add(faFilter);
library.add(faFilterSlash);
library.add(faCircleCheck);

const initializeSentry = (app: ReturnType<typeof createApp>) => {
  Sentry.init({
    app,
    enabled: import.meta.env.PROD,
    dsn: import.meta.env.VITE_SENTRY_URL,
    environment: import.meta.env.MODE,
    release: __APP_VERSION__,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    logErrors: true,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 0.25,
  });
};

const unsubscribe = getAuth(fbApp).onAuthStateChanged((user) => {
  if (!app) {
    router.beforeEach(authGuard);

    app = createApp(App);
    app.use(router);

    if (import.meta.env.PROD) {
      initializeSentry(app);
    }
    app.use(FloatingVue, {
      distance: 10,
      themes: {
        tooltip: {
          delay: { show: 100, hide: 200 },
        },
        'org-switcher': {
          $extend: 'dropdown',
          triggers: ['click'],
          autoHide: true,
          placement: 'bottom',
        },
        'hiber-tooltip': {
          $extend: 'tooltip',
          delay: { show: 100, hide: 200 },
        },
        'actions-dropdown': {
          $extend: 'dropdown',
          triggers: ['click'],
          autoHide: true,
          placement: 'bottom',
        },
        'checkbox-dropdown': {
          $extend: 'dropdown',
          triggers: ['click'],
          autoHide: true,
          placement: 'bottom',
        },
      },
    });
    app.use(MotionPlugin);
    app.use(Toast, {
      newestOnTop: true,
      icon: false,
      position: POSITION.TOP_RIGHT,
    } as PluginOptions);
    app.use(PerfectScrollbar, {
      options: {
        suppressScrollX: true,
      },
    });
    app.use(i18n);
    app.use(pinia);
    app.use(VueQueryPlugin);
    app.use(acl);
    app.use(Oruga, {
      iconPack: 'fal',
      iconComponent: 'font-awesome-icon',
      modal: {
        canCancel: ['escape'],
        scroll: 'clip',
      },
      button: {
        rootClass: 'hiber-btn',
      },
      switch: {
        labelClass: 'o-field__label',
      },
      notification: {
        rootClass: 'h-notification',
      },
      loading: {
        iconClass: 'text-turquiose',
        iconSize: 'medium',
      },
    });

    app.component('FontAwesomeIcon', FontAwesomeIcon);
    app.component('PasswordMeter', PasswordMeter);
    app.component('HField', HField);
    app.component('VueDatePicker', VueDatePicker);

    app.directive('random-bg-image', randomBgImage);
    app.directive('bulk-edit', bulkEdit);
    app.mount('#app');
  }

  if (user && user.email) {
    Sentry.setUser({ email: user.email });
  }

  unsubscribe();
});
