<script setup lang="ts">
import { getAuth } from '@firebase/auth';
import { onBeforeUnmount } from 'vue-demi';
import { useRouter } from 'vue-router';
import { routes } from '@/router/routes';
import { useUserStore } from '@/store/user';
import { State } from '@/utils';
import useSwitchOffline from 'ui/composables/useSwitchOffline';
import { VueQueryDevtools } from '@tanstack/vue-query-devtools';

const router = useRouter();
const userStore = useUserStore();

useSwitchOffline();

const clearAuthListener = getAuth().onAuthStateChanged(async (user) => {
  const { requiresAuth } = router.currentRoute.value.meta;
  if (!user) userStore.$patch({ currentUser: null, isAuthenticated: false });
  if (!user && requiresAuth) {
    await router.push({ path: routes.login.path });
  } else if (user && !requiresAuth && router.currentRoute.value.fullPath !== routes.dashboard.path) {
    try {
      userStore.$patch({ state: State.LOADING, isAuthenticated: true });
      await userStore.fetchCurrentUser();
      await router.push({ path: routes.dashboard.path });
    } catch (error) {
      userStore.$patch({ state: State.READY });
    } finally {
      userStore.$patch({ state: State.READY });
    }
  }
});

onBeforeUnmount(() => {
  clearAuthListener();
});
</script>
<template>
  <router-view></router-view>
  <VueQueryDevtools />
</template>
